.footer-container{
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  background: #1f0099;
  margin-top: 10px;
  display: grid;
  row-gap: 5px;
  column-gap: 5px;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr;
  
}

.section-left{
  padding-top: 90px;
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 2;
  /* background-color: red; */
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
}

.section-right{
  padding-top: 90px;
  grid-column-start: 11;
  grid-column-end: 14;
  grid-row-start: 1;
  grid-row-end: 2;
  /* background-color: greenyellow; */
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
}

.get-in-touch{
  font-size: 12px;
  font-weight: 400;
}

.lets-connect{
  font-size: 12px;
  font-weight: 400;
}

.emailid{
  font-size: 24px;
  font-weight: 400;
  padding-top: 25px;
  color: rgb(255, 255, 255);
  
 
}

.address{
  font-size: 18px;
  font-weight: 400;
  padding-top: 15px;
  color: rgb(255, 255, 255);
}

.SocialAccountLinks{
  font-size: 18px;
  font-weight: 400;
  padding-top: 25px;
  color: rgb(255, 255, 255);
}

.link {
  color: rgb(255, 255, 255);/* Set the initial text color to black */
  text-decoration: none; /* Remove the underline */

  &:hover {
    color: rgb(255, 255, 255); /* Change the text color to white on hover */
    background-color: black; /* Add a black background color on hover for better contrast */
  }
}

.section-developedby{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 2;
  grid-row-end: 3;
  /* background-color: red; */
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  color: white;

  padding-top: 30px;
  padding-bottom: 90px;
}

.DevelopedBy{
  font-size: 16px;
  font-weight: 400;
  color: rgb(255, 255, 255);
}

/* Media Queries for Responsive Design */

/* For smaller devices like iPad smartphones Landscape */
@media (max-width: 768px) {
  .footer-container{
    margin-top: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-left: 50px;
    padding-right: 50px;
  }
  
  .section-left{
    padding-top: 90px;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    /* background-color: red; */
    }
  
  .section-right{
    padding-top: 90px;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
    /* background-color: greenyellow; */
     }
  
  .get-in-touch{
    font-size: 14px;
     }
  
     .lets-connect{
      font-size: 14px;
       }

  .emailid{
    font-size: 24px;
    padding-top: 30px;
    
   }
  
  .address{
    font-size: 18px;
    font-weight: 400;
    padding-top: 10px;
   }
  
  .SocialAccountLinks{
    font-size: 18px;
    font-weight: 400;
    padding-top: 30px;
  }
  
  .section-developedby{
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
    /* background-color: red; */

    padding-top: 20px;
    padding-bottom: 50px;
      
    }
  
  .DevelopedBy{
    font-size: 16px;}
}  
/* For very small devices like phones in portrait mode */
@media (max-width: 480px) {
  .footer-container{
    margin-top: 10px;
    grid-template-columns: 1fr;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .section-left{
    padding-top: 50px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    /* background-color: red; */
  }
  
  .section-right{
    padding-top: 20px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    /* background-color: greenyellow; */
  }
  
  .get-in-touch{
    font-size: 12px;
    font-weight: 400;
  }
  
  .lets-connect{
    margin-top: 10px;
    padding-top: 25px;
    font-size: 12px;
    font-weight: 400;
  }

  .emailid{
    font-size: 20px;
    font-weight: 400;
    padding-top: 20px;
    color: rgb(255, 255, 255);
  }
  
  .address{
    font-size: 16px;
    font-weight: 400;
    padding-top: 10px;
    color: rgb(255, 255, 255);
  }
  
  .SocialAccountLinks{
    font-size: 16px;
    font-weight: 500;
    padding-top: 30px;
  }
  
  .section-developedby{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    /* background-color: red; */
    
    padding-top: 40px;
    padding-bottom: 30px;
  }
  
  .DevelopedBy{
    font-size: 14px;
    padding-bottom: 30px;
  }
  
  
}